import request from '@/utils/request'


// 查询新-产品识别码列表
export function listCode(query) {
  return request({
    url: '/cigarette/identification-code/list',
    method: 'get',
    params: query
  })
}

// 查询新-产品识别码分页
export function pageCode(query) {
  return request({
    url: '/cigarette/identification-code/page',
    method: 'get',
    params: query
  })
}

// 查询新-产品识别码详细
export function getCode(data) {
  return request({
    url: '/cigarette/identification-code/detail',
    method: 'get',
    params: data
  })
}

// 新增新-产品识别码
export function addCode(data) {
  return request({
    url: '/cigarette/identification-code/add',
    method: 'post',
    data: data
  })
}

// 修改新-产品识别码
export function updateCode(data) {
  return request({
    url: '/cigarette/identification-code/edit',
    method: 'post',
    data: data
  })
}

// 删除新-产品识别码
export function delCode(data) {
  return request({
    url: '/cigarette/identification-code/delete',
    method: 'post',
    data: data
  })
}
