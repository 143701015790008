<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Product" prop="brandProduct" >
<!--        <a-input v-model="form.brandProduct" placeholder="" />-->
        <a-select
          placeholder="-Product-"
          style="width: 100%"
          showSearch
          mode="multiple"
          v-model="form.brandProduct"
          optionFilterProp="title"
        >
          <a-select-option v-for="item in brandSkuList" :key="item.id" :value="item.id" :title="item.name + '('+item.productModel+')'">
            {{ item.name+ '('+item.productModel+')' }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Identification Code" prop="code" >
        <a-input v-model="form.code" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="Status" prop="status" >
        <a-select v-model="form.status">
          <a-select-option :key="1">Enable</a-select-option>
          <a-select-option :key="0">Disble</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCode, addCode, updateCode } from '@/api/cigarette/code'
import {listSku} from "@/api/cigarette/goodSku";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        brandProduct: [],

        code: null,

        status: 1,
        productModel: null,
        name: null,
      },
      // 1增加,2修改
      formType: 1,
      brandSkuList: [],
      open: false,
      rules: {
        code: [
          { required: true, message: 'Identification Code cannot be empty', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getBrandSkuList(null);
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    getBrandSkuList(brandId) {
      listSku({
        status:1,
        brandId:brandId
      }).then(res => {
        this.brandSkuList = res.data;
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        brandProduct: [],
        code: null,
        status: 1,
        productModel: null,
        name: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = 'Add'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCode({"id":id}).then(response => {
        this.form = response.data
        this.form.brandProduct = response.data.brandProduct.split(',')
        this.open = true
        this.formTitle = 'Modify'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.form.brandProduct.length > 0
      if(this.form.brandProduct.length > 0) {
        this.form.brandProduct= this.form.brandProduct.join(',')
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCode(this.form).then(response => {
              if(response.code == 20001) {
                this.$message.success(
                  'success',
                  3
                )
              } else {
                this.$message.error(
                  response.message,
                  3
                )
              }

              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCode(this.form).then(response => {
              if(response.code == 20001) {
                this.$message.success(
                  'success',
                  3
                )
              } else {
                this.$message.error(
                  response.message,
                  3
                )
              }

              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
